
.Home-header {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.Home-page {
  cursor: default;
  user-select: none;
  color: #61dafb;
  width: 50px;
  text-align: center;
}

.Home-main {
  padding-block: 50px;
}

.Home-footer {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card {
  width: 300px;
  height: 480px;
}

.card-media {
  position: relative;
}
.card-media:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.2) 80%, rgba(0,0,0,0.01) 100%);
}
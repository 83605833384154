.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 50px 0;
}

.card {
  margin-block: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 40px;
}
.card-image {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 15px;
  overflow: hidden;
  height: 300px;
}
.card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.episodes-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-block: 30px;
}
.episodes {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}
.episode-card {
  cursor: default;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-radius: 15px;
  background-color: rgb(24, 24, 24);
}
.episode-card__title {
  display: flex;
  flex-direction: column;
  gap: 3px;
}